<template>
  <div style="height: 100vh; overflow: hidden">
    <iframe
      :src="
        'student/zb_content.html?phone=' +
        this.$route.query.phone +
        '&grade_id=' +
        this.$route.query.grade_id +
        '&play_id=' +
        this.$route.query.play_id
      "
      frameborder="0"
      style="width: 100%; height: 100%; overflow: hidden"
    ></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    window.addEventListener("message", this.handleMessage);
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听器
    window.removeEventListener("message", this.handleMessage);
  },
  methods: {
    handleMessage(event) {
      console.log(event.data.close);
      if (event.data.close == "...") {
        setTimeout(() => {
          window.close();
        }, 2000);
      }
      // setTimeout(window.close(), 50000);
      if (event.source !== window.parent) {
        return;
      }
    },
  },
};
</script>
